import React from 'react';

import Footer from './content/footer';
import ContentNav from './content/nav';
import Theme from './content/theme';

import 'src/style/index.css';

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <ContentNav />
      <div className="flex-grow px-4 m-auto max-w-screen-xl w-full sm:px-6">
        {/*<div className="mt-10 mb-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">*/}
        <div className="mt-10 mb-16 sm:mt-12 md:mt-16 lg:mt-20">
          <div className="max-w-3xl mx-auto w-full">
            <Theme>{children}</Theme>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
