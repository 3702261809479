import React from 'react';
import classnames from 'classnames';

/**
 * @param {Array<Component>} $1.headers headers
 * @param {Array<Array<Component>>} $1.lines data to display. May be string (values) or full component (links etc)
 */
const Table = ({ className, headers, lines = [], footers, compact = true }) => {
  return (
    <div className={classnames('flex flex-col', className)}>
      <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle border-gray-200 sm:border-b sm:shadow sm:rounded-lg">
          <table className="min-w-full">
            {headers && headers.length > 0 && (
              <thead>
                <tr>
                  {headers.map((h) => (
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 leading-4">
                      {h}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody className="bg-white">
              {lines.map((data) => (
                <tr key={data.key}>
                  {data.map((d) => (
                    <td
                      className={classnames(
                        'px-6 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900',
                        {
                          'py-4': !compact,
                          'py-2': compact,
                        }
                      )}
                    >
                      {d}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            {footers && footers.length > 0 && (
              <tfoot>
                {footers.map((h) => (
                  <th className="px-6 py-3 text-sm font-medium text-left text-gray-500 border-b border-gray-200 bg-gray-50">
                    {h}
                  </th>
                ))}
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

/**
 * @param {Array<Component>} $1.headers headers
 * @param {Array<Array<Component>>} $1.lines data to display. May be string (values) or full component (links etc)
 */
export const SecondaryTable = ({
  className,
  headers,
  lines,
  footers,
  compact = true,
}) => {
  return (
    <div className={classnames('flex flex-col', className)}>
      <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            {headers && headers.length > 0 && (
              <thead>
                <tr>
                  {headers.map((h) => (
                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase bg-gray-50 leading-4">
                      {h}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody className="bg-white">
              {lines.map((data) => (
                <tr
                  key={data.key}
                  className="border border-collapse border-gray-100"
                >
                  {data.map((d) => (
                    <td
                      className={classnames(
                        'px-6 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900',
                        {
                          'py-4': !compact,
                          'py-2': compact,
                        }
                      )}
                    >
                      {d}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            {footers && footers.length > 0 && (
              <tfoot>
                {footers.map((h) => (
                  <th className="px-6 py-3 text-sm font-medium text-left text-black border-b border-gray-200 bg-gray-50">
                    {h}
                  </th>
                ))}
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;
