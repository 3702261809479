import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classnames from 'classnames';

const Logo = ({ className = 'h-12' }) => {
  const {
    file: { publicURL: logo },
  } = useStaticQuery(graphql`
    query myQuery {
      file(name: { eq: "logo" }) {
        publicURL
      }
    }
  `);

  return (
    <img
      className={classnames('block w-auto', className)}
      src={logo}
      alt="L'enfant d'eau logo"
    />
  );
};

export default Logo;
