import React from 'react';
import classnames from 'classnames';

const H1 = ({ className, children }) => (
  <h1
    className={classnames(
      'text-3xl font-bold leading-tight text-gray-900 px-2 md:px-0',
      className
    )}
  >
    {children}
  </h1>
);

export default H1;
