import React from 'react';

const Splash = ({ children }) => {
  return (
    <div className="absolute block top-0 left-0 flex justify-center content-center m-auto w-screen h-screen">
      {children}
    </div>
  );
};

export default Splash;
