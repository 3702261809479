export { default as Link } from './link';

export { default as H1 } from './h1';
export { default as H2 } from './h2';
export { default as H3 } from './h3';

export { default as P } from './p';

export { default as Card } from './card';
export { default as Important } from './important';
export { default as Loading } from './loading';

export { default as Splash } from './splash';
export { default as Strong } from './strong';
export { default as Table } from './table';

export { default as ErrorAlert } from './alerts/error';

export { default as Header } from './header';

export { default as Tab } from './tab';
