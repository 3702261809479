import React from 'react';
import classnames from 'classnames';
import { navigate, Link } from 'gatsby';

const PillLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: classnames(
          'ml-4 px-3 py-2 font-medium text-sm leading-5 rounded-md focus:outline-none ',
          {
            'text-blue-700 bg-blue-100 focus:text-blue-800 focus:bg-blue-200': isCurrent,
            'text-gray-500 hover:text-gray-700 focus:text-blue-600 focus:bg-blue-50': !isCurrent,
          }
        ),
      };
    }}
  />
);

const Tabs = ({ className, links = [], location = {}, ...otherProps }) => {
  const moveTo = (e) => navigate(e.target.value);
  return (
    <div className={classnames('', className)} {...otherProps}>
      <div className="sm:hidden">
        <select
          aria-label="Selected tab"
          value={location.pathName}
          className="form-select block w-full"
          onChange={moveTo}
        >
          {links.map((l) => (
            <option value={l.to}>{l.label}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex">
          {links.map((l) => (
            <PillLink to={l.to}>{l.label}</PillLink>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;
