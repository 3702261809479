import React from 'react';
import classnames from 'classnames';

const Strong = ({ className, children, ...otherProps }) => (
  <strong className={classnames('font-semibold', className)} {...otherProps}>
    {children}
  </strong>
);

export default Strong;
