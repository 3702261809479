import classnames from 'classnames';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import { useAuth } from 'src/components/authentication';
import { useConfig } from 'src/components/configuration';
import Logo from 'src/components/logo';

const ContentNav = () => {
  const rsConfig = useConfig();

  const [isMenuOpen, setMenuOpen] = useState(false);

  const {
    loginState: { status, userProfile },
    init,
  } = useAuth();

  init();

  const isConnected = status === 'loggedIn';
  const userRole = isConnected ? userProfile.role : null;

  const isInscriptionOpen =
    rsConfig.status === 'loaded'
      ? rsConfig.data.data.inscription_ouverte
      : false;

  const isReinscriptionOpen =
    rsConfig.status === 'loaded'
      ? isConnected &&
        userRole === 'famille' &&
        userProfile.famille.isLastSeasonMember &&
        rsConfig.data.data.reinscription_ouverte
      : false;

  const isReinscriptionCAOpen =
    rsConfig.status === 'loaded'
      ? isConnected &&
        userRole === 'famille' &&
        userProfile.famille.isLastSeasonMember &&
        userProfile.famille.is_membreCA &&
        rsConfig.data.data.reinscription_ca_ouverte
      : false;

  let userActions = {
    primaryAction: {
      label: 'Inscription',
      to: '/inscription',
      disable: !isInscriptionOpen,
    },
    secondaryAction: {
      label: 'Connexion',
      to: '/login',
    },
  };

  switch (userRole) {
    case 'famille':
      userActions = {
        primaryAction: {
          label: 'Inscription',
          to: '/famille/inscription',
          disable:
            !isReinscriptionCAOpen &&
            !isReinscriptionOpen &&
            !isInscriptionOpen,
        },
        secondaryAction: {
          label: 'Mon compte',
          to: '/famille',
        },
      };
      break;
    case 'admin':
      userActions = {
        primaryAction: {
          label: 'Administration',
          to: '/admin',
        },
        secondaryAction: undefined,
      };
      break;
  }

  const links = [
    {
      label: 'Nouvelles',
      to: '/nouvelles',
    },
    {
      label: "L'association",
      to: '/association',
    },
    {
      label: 'Pédagogie',
      to: '/pedagogie',
    },
    {
      label: "L'activité",
      to: '/activite',
    },
    {
      label: 'Tarifs',
      to: '/tarifs',
    },
    { label: 'Aide', to: '/aide' },
    { label: 'Contact', to: '/contact' },
  ];

  return (
    <div className="relative bg-white">
      <div className="px-4 mx-auto max-w-7xl sm:px-6">
        <div className="flex items-center justify-between py-6 border-b-2 border-gray-100 md:justify-start md:space-x-10">
          <div className="lg:w-0 lg:flex-1">
            <Link to="/" className="flex">
              <Logo />
            </Link>
          </div>

          <div className="-my-2 -mr-2 md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <svg
                className="w-6 h-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <nav className="hidden md:flex space-x-10">
            {links.map((l) => (
              <Link
                to={l.to}
                className="text-base font-medium text-gray-500 leading-6 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
              >
                {l.label}
              </Link>
            ))}
          </nav>
          <div className="items-center justify-end hidden md:flex space-x-8 md:flex-1 lg:w-0">
            {userActions.secondaryAction && (
              <Link
                to={userActions.secondaryAction.to}
                className="text-base font-medium text-gray-500 whitespace-no-wrap leading-6 hover:text-gray-900 focus:outline-none focus:text-gray-900"
              >
                {userActions.secondaryAction.label}
              </Link>
            )}

            {userActions.primaryAction && (
              <span className="inline-flex rounded-md shadow-sm">
                {userActions.primaryAction.disable ? (
                  <div className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-white whitespace-no-wrap bg-gray-300 border border-transparent cursor-default leading-6 rounded-md focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition ease-in-out duration-150">
                    {userActions.primaryAction.label}
                  </div>
                ) : (
                  <Link
                    to={userActions.primaryAction.to}
                    className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-white whitespace-no-wrap bg-blue-600 border border-transparent leading-6 rounded-md hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
                  >
                    {userActions.primaryAction.label}
                  </Link>
                )}
              </span>
            )}
          </div>
        </div>
      </div>

      {/*
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  */}
      <div
        className={classnames(
          'absolute inset-x-0 top-0 p-2 transition transform origin-top-right md:hidden z-40',
          {
            hidden: !isMenuOpen,
          }
        )}
      >
        <div className="rounded-lg shadow-lg">
          <div className="bg-white rounded-lg shadow-xs divide-y-2 divide-gray-50">
            <div className="px-5 pt-5 pb-6 space-y-6">
              <div className="flex items-center justify-between">
                <div>
                  <Logo />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                  >
                    <svg
                      className="w-6 h-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <nav className="grid row-gap-8">
                  {links.map((l) => (
                    <Link
                      to={l.to}
                      className="flex items-center p-3 -m-3 space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <div className="text-base font-medium text-gray-900 leading-6">
                        {l.label}
                      </div>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="px-5 py-6 space-y-6">
              <div className="space-y-6">
                {userActions.secondaryAction && (
                  <p className="text-base font-medium text-center text-gray-500 leading-6">
                    <Link
                      to={userActions.secondaryAction.to}
                      className="text-blue-600 hover:text-blue-500 transition ease-in-out duration-150"
                    >
                      {userActions.secondaryAction.label}
                    </Link>
                  </p>
                )}
                {userActions.primaryAction && (
                  <span className="flex w-full rounded-md shadow-sm">
                    {userActions.primaryAction.disable ? (
                      <div className=" w-full inline-flex items-center justify-center px-4 py-2 text-base font-medium text-white whitespace-no-wrap bg-gray-300 border border-transparent cursor-default leading-6 rounded-md focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition ease-in-out duration-150">
                        {userActions.primaryAction.label}
                      </div>
                    ) : (
                      <Link
                        to={userActions.primaryAction.to}
                        className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white bg-blue-600 border border-transparent leading-6 rounded-md hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150"
                      >
                        {userActions.primaryAction.label}
                      </Link>
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentNav;
