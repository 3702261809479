import React from 'react';
import classnames from 'classnames';

const Important = ({ className, children, ...otherProps }) => (
  <div
    className={classnames(
      'text-md font-semibold tracking-wide text-gray-800 my-6 mx-3 text-center',
      className
    )}
    {...otherProps}
  >
    {children}
  </div>
);

export default Important;
