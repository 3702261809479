import React from 'react';
import classnames from 'classnames';

const H2 = ({ className, children }) => (
  <h2
    className={classnames(
      'text-2xl font-semibold leading-tight text-gray-900  px-2 md:px-0',
      className
    )}
  >
    {children}
  </h2>
);

export default H2;
