import React from 'react';
import { navigate } from 'gatsby';
import classnames from 'classnames';
import { TailSpin as Loader } from 'svg-loaders-react';

const wrapWithConfirmation = (onClick, message = 'Êtes vous sur?') => (e) => {
  const answer = window.confirm(message);
  if (!answer) return;
  else onClick(e);
};

const colorClasses = {
  red:
    'bg-red-600 hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red active:bg-red-700 text-white ',
  blue:
    'bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 text-white ',
  gray:
    'bg-gray-600 hover:bg-gray-500 focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 text-white ',
  light:
    'bg-gray-200 hover:bg-gray-400 focus:border-gray-500 focus:shadow-outline-gray active:bg-gray-600 text-gray-900',
};

const ActionButton = ({
  className = '',
  children,
  to,
  disabled = false,
  onClick = () => {},
  color = 'blue',
  mustConfirm = false,
  confirmationMessage,
  isLoading = false,
  type = 'button',
  ...otherProps
}) => {
  const internalOnClick = to
    ? (e) => {
        e.preventDefault();
        onClick(e);
        navigate(to);
      }
    : onClick;
  return (
    <button
      type={type}
      disabled={disabled || isLoading}
      className={classnames(
        `inline-flex items-center px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md focus:outline-none transition ease-in-out duration-150`,
        isLoading || disabled ? colorClasses.gray : colorClasses[color],
        {
          'cursor-wait': isLoading,
          'cursor-default': disabled,
        },
        className
      )}
      onClick={
        mustConfirm
          ? wrapWithConfirmation(internalOnClick, confirmationMessage)
          : internalOnClick
      }
      {...otherProps}
    >
      <span>{children}</span>
      <Loader
        className={classnames('h-4 w-4 ml-2', {
          'inline-block': isLoading,
          hidden: !isLoading,
        })}
      />
    </button>
  );
};

export default ActionButton;
