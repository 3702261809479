import React from 'react';
import classnames from 'classnames';

const P = ({ className, children, ...otherProps }) => (
  <p className={classnames('px-2 md:px-0', className)} {...otherProps}>
    {children}
  </p>
);

export default P;
