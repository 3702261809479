import React from 'react';
import classnames from 'classnames';

import H2 from './h2';

const Card = ({ className, children, title, ...otherProps }) => (
  <div
    className={classnames(
      'bg-white overflow-hidden shadow rounded-sm sm:rounded-lg',
      className
    )}
    {...otherProps}
  >
    <div className="px-4 py-5 sm:p-6">
      {title && <H2>{title}</H2>}
      {children}
    </div>
  </div>
);

export default Card;
