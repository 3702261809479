import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

const StyledLink = ({
  className = '',
  newTab = true,
  to,
  href,
  children,
  ...otherProps
}) => {
  if (!href && !to)
    return (
      <span className="text-red-600 font-extrabold uppercase tracking-wider">
        Link sans destination
      </span>
    );
  if (href) {
    const newTabProps = newTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {};
    return (
      <a
        className={classnames(
          'text-blue-800 hover:text-blue-700 underline cursor-pointer font-thin transition ease-in-out duration-150',
          className
        )}
        href={href}
        {...newTabProps}
        {...otherProps}
      >
        {children}
      </a>
    );
  }
  if (to) {
    return (
      <Link
        className={classnames(
          'text-blue-800 hover:text-blue-700 underline cursor-pointer transition font-light ease-in-out duration-150',
          className
        )}
        to={to}
        {...otherProps}
      >
        {children}
      </Link>
    );
  }
};

export default StyledLink;
