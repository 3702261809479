import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import classnames from 'classnames';

import Table from 'src/components/design-system/table';
import { Link } from 'gatsby';
import * as DesignSystem from 'src/components/design-system';

export const H2 = (props) => (
  <h2
    {...props}
    className="text-3xl font-extrabold text-center text-gray-900 leading-9 sm:text-4xl sm:leading-10 lg:mb-8"
  />
);

export const H3 = (props) => (
  <h3 {...props} className="mt-4 text-lg text-gray-900" />
);

export const P = ({ className, ...props }) => (
  <p
    {...props}
    className={classnames('mt-4 font-thin text-gray-900', className)}
  />
);

export const List = (props) => (
  <ul {...props} className="font-thin list-disc list-outside ml-8"></ul>
);

export const Code = (props) => (
  <code {...props} className="block mt-4 font-mono text-sm" />
);

export const A = ({ href, children }) => {
  const hrefSplit = href.split(':');
  if (hrefSplit.length >= 2 || href.startsWith('/'))
    return <DesignSystem.Link href={href}>{children}</DesignSystem.Link>;
  return <DesignSystem.Link to={href}>{children}</DesignSystem.Link>;
};

const makeLines = (lines) =>
  lines.map((l) => l.children.map((c) => c.children.value));
export const TableMd = (props) => {
  console.log(props.children);
  return <Table headers={['a', 'b']} lines={[['a', 'b']]} />;
};

const Theme = ({ children }) => (
  <MDXProvider
    components={{
      h2: H2,
      h3: H3,
      p: P,
      ul: List,
      table: TableMd,
      a: A,
      code: Code,
    }}
  >
    {children}
  </MDXProvider>
);

export default Theme;
