import React from 'react';
import classnames from 'classnames';

import ActionButton from 'src/components/design-system/buttons/action';
import StyledLink from 'src/components/design-system/link';

const Header = ({
  className,
  children,
  title,
  action = {},
  secondaryAction = {},
  back = {},
  ...otherProps
}) => (
  <div
    className={classnames('flex md:flex-row flex-wrap px-2 md:px-0', className)}
    {...otherProps}
  >
    {back.label && (
      <StyledLink className="font-light w-full" to={back.to}>
        {back.label}
      </StyledLink>
    )}
    <h1 className="flex-grow text-3xl font-bold leading-tight text-gray-90">
      {title}
    </h1>
    <div className="flex flex-row">
      {secondaryAction.label && (
        <ActionButton className="mr-4" {...secondaryAction}>
          {secondaryAction.label}
        </ActionButton>
      )}
      {action.label && <ActionButton {...action}>{action.label}</ActionButton>}
    </div>
    {children}
  </div>
);

export default Header;
