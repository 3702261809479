import React from 'react';
import classnames from 'classnames';

const H3 = ({ className, children, ...otherProps }) => (
  <h3
    className={classnames('text-md font-semibold', className)}
    {...otherProps}
  >
    {children}
  </h3>
);

export default H3;
